.wrapper {
  @apply relative flex gap-4 overflow-x-visible;
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #000d0e65;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #4c4f50;
  }

  &::-webkit-scrollbar {
    height: 4px; //for vertical scrollbar
  }
}
.customPagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-top: 16px;
}

.customPaginationBullet {
  width: 12px;
  height: 12px;
  background-color: #d9d9d9;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  position: absolute;
  bottom: 0;
  left: 0;
}

.customPaginationBullet.swiper-pagination-bullet-active {
  background-color: #00aaff;
  transform: scale(1.2);
}
