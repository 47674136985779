@mixin animated-gradient-border($gradient-colors, $border-width: 2px) {
  position: relative;
  background-color: #000;
  color: #fff;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  text-transform: uppercase;
  outline: none;
  overflow: hidden;
  z-index: 1;

  &::before {
    content: '';
    position: absolute;
    top: -#{$border-width};
    left: -#{$border-width};
    right: -#{$border-width};
    bottom: -#{$border-width};
    background: $gradient-colors;
    border-radius: calc(30px + #{$border-width});
    z-index: -2;
    animation: borderAnimation 3s infinite linear;
    background-size: 200% 200%;
  }

  &::after {
    content: '';
    position: absolute;
    top: $border-width;
    left: $border-width;
    right: $border-width;
    bottom: $border-width;
    background-color: #000;
    border-radius: 30px;
    z-index: -1;
  }

  &:hover {
    opacity: 0.9;
  }
}

// Keyframes for the animated gradient
@keyframes borderAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

// Button styles with animated gradient borders
.web {
  @include animated-gradient-border(linear-gradient(90deg, #e0a6ec, #627af5, #40e9f9, #1efcfd));
}

.windows {
  @include animated-gradient-border(linear-gradient(90deg, #1be4ff, #30f7ba, #f3ba2f, #fbce00));
}

.telegram {
  @include animated-gradient-border(linear-gradient(90deg, #e0a6ec, #627af5, #40e9f9, #1efcfd));
}

.crypto {
  @include animated-gradient-border(linear-gradient(90deg, #ffe85e, #8cb151, #23af91, #30f7ba));
}

.bitcoin {
  @include animated-gradient-border(linear-gradient(90deg, #fffed4, #ffff59, #eabe15, #ffe85e));
}

.bitcoin_hunt {
  @include animated-gradient-border(linear-gradient(90deg, #1BE4FF, #30F7BA, #F3BA2F, #FBCE00));
}

.games-hub {
  text-align: center;
  padding: 20px;
}

.game-cards {
  @apply grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-6;
}

.game-card {
  @apply w-full bg-[#1C1F26];
  color: white;
  padding: 12px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  text-align: center;
}

.dynamic-swiper {
  @apply mb-5 lg:mb-10 rounded-lg w-full;
  height: 400px;
  background: #1c1f26;

  .slide-container {
    @apply rounded-lg p-3 w-full h-full;
  }
}

