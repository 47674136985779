.mySwiper {
  padding-right: 5%;
  padding-left: 5%;
}
.swiper-button-prev,
.swiper-button-next {
  background-color: rgba(212, 212, 212, 0.5); // Semi-transparent red background
  color: rgb(0, 0, 0); // White text color for visibility
  width: 40px; // Adjust as necessary
  height: 40px; // Adjust as necessary
  border-radius: 50%;
  position: absolute;
  top: 55%; // Center vertically
  transform: translateY(-50%); // Align center vertically
  z-index: 10; // Ensure they are above other elements
  background-size: 20px; // Size of the arrow
  background-position: center; // Center the arrow
  background-repeat: no-repeat; // Do not repeat the background image
  padding: 12px;
  cursor: pointer;
  &:hover {
    background-color: rgba(187, 174, 174, 0.8); // Semi-transparent red background
  }
}

.swiper-button-prev {
  left: -5%;
}
.swiper-button-next {
  right: -5%;
}
@media (max-width: 1600px) {
  .swiper-button-prev,
  .swiper-button-next {
    width: 30px;
    height: 30px;
    background-size: 15px;
    padding: 9px;
  }

  .swiper-button-prev {
    left: 0px;
  }

  .swiper-button-next {
    right: 0px;
  }
}
@media (max-width: 1440px) {
  .swiper-button-prev,
  .swiper-button-next {
    width: 30px;
    height: 30px;
    background-size: 15px;
    padding: 9px;
  }

  .swiper-button-prev {
    left: 10px;
  }

  .swiper-button-next {
    right: 10px;
  }
}
@media (max-width: 768px) {
  .swiper-button-prev,
  .swiper-button-next {
    width: 30px;
    height: 30px;
    background-size: 15px;
    padding: 9px;
  }

  .swiper-button-prev {
    left: 10px;
  }

  .swiper-button-next {
    right: 10px;
  }
}

@media (max-width: 480px) {
  .swiper-button-prev,
  .swiper-button-next {
    width: 25px;
    height: 25px;
    background-size: 10px;
    padding: 7px;
  }

  .swiper-button-prev {
    left: 10px;
  }

  .swiper-button-next {
    right: 10px;
  }
}
