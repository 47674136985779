// Variables for colors and spacing
$card-background-color: #000000;
$text-color: #ffffff;
$button-background-color: #5a5a9a;
$button-hover-color: #7a7abb;
$border-radius: 10px;

.card {
  @apply w-full mx-4;
  background-color: $card-background-color;
  border-radius: $border-radius;
  // padding: 20px;
  text-align: center;
  // width: 250px;
  color: $text-color;

  .icon {
    @apply mx-auto h-[200px] w-[200px] mb-4;
    // img {
    //   width: 50px;
    //   height: 50px;
    // }
  }

  h3 {
    margin: 10px 0;
  }

  p {
    font-size: 14px;
  }

  .button-group {
    margin-top: 15px;

    .card-button {
      @apply bg-[#D711F6] rounded-full px-2 py-1 bg-opacity-20;
      color: $text-color;
      border: none;
      // padding: 8px 12px;
      margin: 1px;
      cursor: default;
      transition: background-color 0.3s;

      // &:hover {
      //   background-color: $button-hover-color;
      // }
    }
  }
}
