.container {
  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  &::-webkit-scrollbar:vertical {
    width: 8px;
  }
  &::-webkit-scrollbar:horizontal {
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(224, 218, 218, 0.5);
    border-radius: 10px;
    border: 2px solid #414141;
    margin-right: 10px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #414141;
  }
}

.gradient_audit_box {
  background: radial-gradient(circle at 50% 0%, #363843 6%, #0a293900 50%);
  background-color: #070914;
}
