.wrapper {
  @apply container w-full;

  .title {
    @apply mb-10;
    .parentTitle {
      @apply font-light text-center  text-3xl  md:text-4xl xl:text-5xl font-thin tracking-widest;
    }
    .parentTitle_fr {
      @apply  text-center  text-[22px]  md:text-4xl xl:text-5xl font-thin tracking-widest;
    }
  }
}
