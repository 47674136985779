.videoContainer {
  @apply flex flex-wrap gap-[15px] mt-16 gap-y-11 justify-center lg:justify-start;
}
.downloadItemContainer {
  @apply flex  gap-[15px] items-end justify-end mt-3;
}
.CustomVideoContainer {
  @apply 2xl:w-[451px] 2xl:h-[253px] md:w-[330px] md:h-[199px] w-[330px] h-[185px] rounded-[8px] lg:rounded-[9px] relative;
}

.circle {
  @apply bg-[#848888] opacity-[.7] w-[64px] h-[64px] rounded-[64px] flex justify-center items-center absolute top-[50%] left-[50%]  inset-0 z-[1] cursor-pointer;
  transform: translate(-50%, -50%);
  .triangle {
    width: 0;
    height: 0;
    border-top: 12px solid transparent;
    border-left: 23px solid white;
    border-bottom: 12px solid transparent;
  }
}
