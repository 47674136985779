.wrapper {
  @apply relative flex flex-col justify-center h-full px-[16px] xl:px-0 ;


  

  .header {
    @apply flex h-full gap-[17px] md:gap-[13px] lg:gap-[5px] xl:gap-[13px] 2xl:gap-[5px] flex-col;

    .headerTitle {
      @apply relative flex items-center justify-center gap-[25px] font-normal text-[30px] md:text-[35px] xl:text-[44px] mb-[25px] lg:mb-[30px];
    }
    .headerStrip {
      @apply relative flex justify-between h-[90px] bg-[#1D2027] rounded-[8px] px-[5px] lg:px-[23px];

      .startSection {
        @apply relative;
      }

      .endSection {
        @apply relative;
      }
    }
  }

  .followBtn {
    @apply w-[fit-content] h-[58] me-[20px] font-bold flex items-center 
    justify-center bg-white rounded-[10px] text-[#040404] text-[16px] md:text-[20px] 
    px-[15px] py-[6px] md:px-[24px] md:py-[10px];
  }


}
.swiper-button-prev,
.swiper-button-next {
    background-color: rgba(212, 212, 212, 0.5); // Semi-transparent red background
    color: rgb(0, 0, 0); // White text color for visibility
    width: 40px; // Adjust as necessary
    height: 40px; // Adjust as necessary
    border-radius: 50%;
    position: absolute;
    top: 70%; // Center vertically
    transform: translateY(-50%); // Align center vertically
    z-index: 10; // Ensure they are above other elements
    background-size: 20px; // Size of the arrow
    background-position: center; // Center the arrow
    background-repeat: no-repeat; // Do not repeat the background image
    padding: 12px;
    cursor: pointer;
    &:hover {
      background-color: rgba(187, 174, 174, 0.8); // Semi-transparent red background
    }
}

.swiper-button-prev {
  left: -5%; 
}
.swiper-button-next {
  right: -5%; 
}
@media (max-width: 1600px) {
  .swiper-button-prev,
  .swiper-button-next {
      width: 30px;
      height: 30px;
      background-size: 15px;
      padding: 9px;
  }

  .swiper-button-prev {
      left: 0px;
  }

  .swiper-button-next {
      right: 0px;
  }
}
@media (max-width: 1440px) {
  .swiper-button-prev,
  .swiper-button-next {
      width: 30px;
      height: 30px;
      background-size: 15px;
      padding: 9px;
  }

  .swiper-button-prev {
      left: 10px;
  }

  .swiper-button-next {
      right: 10px;
  }
}
@media (max-width: 768px) {
  .swiper-button-prev,
  .swiper-button-next {
      width: 30px;
      height: 30px;
      background-size: 15px;
      padding: 9px;
  }

  .swiper-button-prev {
      left: 0px;
  }

  .swiper-button-next {
      right: 0px;
  }
}

@media (max-width: 480px) {
  .swiper-button-prev,
  .swiper-button-next {
      width: 25px;
      height: 25px;
      background-size: 10px;
      padding: 7px;
  }

  .swiper-button-prev {
    left: 0px;
}

.swiper-button-next {
    right: 0px;
}
}

.disabled {
  display: none;  /* Change from 'flex: hidden' which is incorrect */
  cursor: not-allowed;
}
