.wrapper {
  @apply relative flex flex-col w-[320px] sm:w-[390px] md:w-[449px]
  rounded-[8px] p-2.5 pb-1 sm:p-4 sm:pb-1.5 bg-gradient-to-tr from-[#2C3440] to-[#393d48];

  .cardHeader {
    @apply relative flex gap-[20px] px-[5px] lg:px-[12px] w-full h-[40px];

    .startSection {
      @apply relative flex items-center justify-between gap-[10px] h-[30px];
      .title {
        @apply font-semibold text-[15px] md:text-[20px];
      }
    }

    .endSection {
      @apply relative flex items-center h-[30px] text-[#848484] font-normal text-[15px] md:text-[18px];
    }
  }
  .cardMiddle {
    @apply relative w-full line-clamp-2 mt-2 md:mt-[16px] mb-2 md:mb-[20px] text-[14px] md:text-[16px] xl:text-[18px] px-[10px] md:px-[20px];
  }

  .cardContent {
    @apply grow;
  }

  .cardFooter {
    @apply relative w-full my-2
        flex justify-between;
    .bottomCta {
      @apply flex items-center gap-2 cursor-pointer md:h-5 h-3.5;

      svg {
        @apply h-full w-auto;
      }
    }
  }
}

.circle {
  @apply bg-[#848888] opacity-[.7] w-[64px] h-[64px] rounded-[64px] flex justify-center items-center absolute top-[50%] left-[50%]  inset-0 z-[1] cursor-pointer;
  transform: translate(-50%, -50%);
  .triangle {
    width: 0;
    height: 0;
    border-top: 12px solid transparent;
    border-left: 23px solid white;
    border-bottom: 12px solid transparent;
  }
}
