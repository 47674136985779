.container {
  @apply bg-[#1C1F26] max-w-[300px] rounded-md flex flex-col min-w-[300px]  min-h-[427px] gap-3 justify-between;
}
.imageContainer {
  // @apply bg-[#1C1F26] max-w-[500px];
  .image {
    // @apply bg-[#1C1F26] max-w-[500px];
  }
}
.description {
  @apply h-auto overflow-y-auto -ms-[12px] px-[12px] ;
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);

    border-radius: 10px;

    background-color: #5e6169;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;

    background-color: white;
  }
  &::-webkit-scrollbar {
    width: 4px;
  }
}