.commandCenterContainer {
  @apply px-4 sm:px-7 md:px-10 lg:px-16 xl:px-20 grid grid-cols-12 justify-between pt-12 pb-8 lg:pb-0 items-end;
  text-align: center;
  background-color: #111;
  color: white;
  width: 100%;
  min-height: auto;
}

// h1 {
//   margin-bottom: 10px;
//   font-size: 2rem;
// }

// p {
//   margin-bottom: 30px;
//   font-size: 1.2rem;
// }

.cardsGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.card {
  background-color: #222;
  padding: 20px;
  border-radius: 8px;
}

.btn {
  margin-top: 10px;
  background-color: #47feff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn:hover {
  border-color: #37c9c9;
}

.mainBtn {
  @apply rounded-lg px-4 py-2;
  border: 1px solid #47feff;
  color: #47feff;
  cursor: pointer;
  transition: border-color 0.3s ease;
}
